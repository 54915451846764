
<template>
  <v-app>
    <v-main class="login_background">
      <v-row justify="center" align="center" style="height:95vh">
        <v-col class="d-flex flex-column justify-center align-center">
          <div class="pa-3 display-1 black--text text-center" > 
            <div class="mb-10 mt-n3 font-weight-bold">
              Uh-oh! Essa página não foi encontrada
            </div>
            <v-btn class="mt-10" color="error" @click="$router.push('/')" rounded><v-icon left>mdi-arrow-left</v-icon>voltar</v-btn>
          </div>
          <div class="mb-10 mt-15 font-weight-normal text-center title">
            Se você acha que está vendo isso por engano, entre em contato com nosso time de suporte em 
            <a href="mailto:contato@cabbio.com.br" class="font-weight-bold gtxt primary--text"> contato@cabbio.com.br </a>
          </div>

        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
// import logo from "@/assets/logo_checkbits.png";
// import logoErr from "@/assets/404.png";

export default {
  data() {
    return {
      key: null,
      // logoCheckbits: logoErr

    }
  },
  methods: {
    names() {
      
    },
    ...mapActions({
      // function: 'Module/action'
    })
  },
  computed: {
    name() {
      return null 
    },
    ...mapState({
      // data: state=> state.Module.state_var
    })
  },
  watch: {
    data(nv,ov) {
      if (nv){
      } else if (nv == false){
      }
    }
  },
}
</script>

<style scoped>
body {
  background-color: white !important;
}
.gtxt {
  color: #1dd3bd;
}
.login_background {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  background: url('../assets/background_login.png');
  /* background: url('../../assets/background_login.jpg') */
}
</style>